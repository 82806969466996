/* Base Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100vh;
  overflow-y: auto;
  padding: 2rem 0;
}

.modal-content {
  background: #1a1d21;
  border: 1px solid #373b40;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: white;
  position: relative;
  max-height: 80vh;
}

.modal-header {
  padding: 16px;
  border-bottom: 1px solid #373b40;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a2e33;
}

.modal-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

/* Base Container Styles */
.flashcard-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #000000;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.flashcard-header {
  margin-bottom: 2rem;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.flashcard-header h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-size: 30px;
  font-weight: bold;
}

/* Flashcard Styles */
.flashcard {
  background-color: #2a2e33;
  border: 1px solid #373b40;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  min-height: 400px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
}

/* Word Title Section */
.word-header {
  text-align: center;
  margin-bottom: 1rem;
}

.word-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.word-title {
  font-size: 2rem;
  color: white;
  margin: 0;
}

.part-of-speech {
  font-size: 1.2rem;
  color: #8a8d91;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
}

/* Details Link */
.details-link {
  display: inline-block;
  color: #0084ff;
  text-decoration: none;
  margin-top: 0.5rem;
  font-size: 1rem;
  transition: color 0.2s;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: rgba(0, 132, 255, 0.1);
}

.details-link:hover {
  color: #ffffff;
  background-color: #0084ff;
  text-decoration: none;
}

/* Content Sections */
.content-section {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 6px;
}

.content-section:last-child {
  margin-bottom: 0;
}

.content-section h4 {
  font-size: 1.2rem;
  color: #8a8d91;
  margin-bottom: 0.75rem;
}

.content-section p {
  color: #d1d5db;
  line-height: 1.6;
  margin: 0;
  padding: 0.75rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  word-break: break-word;
}

.examples-list {
  list-style: none;
  padding: 0.75rem 1rem;
  margin: 0;
  border-left: 2px solid #373b40;
}

.examples-list li {
  color: #8a8d91;
  line-height: 1.6;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.examples-list li:last-child {
  margin-bottom: 0;
}

.back-link-container {
  position: sticky;
  top: 0;
  background-color: #1a1d21;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #373b40;
  z-index: 10;
  box-shadow: 0 4px 6px -6px rgba(0, 0, 0, 0.2);
}

.back-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #0084ff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: all 0.2s ease;
  background: none;
  border: none;
  cursor: pointer;
}

.back-link:hover {
  color: #0073e6;
  transform: translateX(-3px);
}

.back-link svg {
  width: 16px;
  height: 16px;
}

.definitions-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.definitions-container::-webkit-scrollbar {
  width: 8px;
}

.definitions-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.definitions-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.definitions-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.15);
}

.flashcard-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

/* Media Container */
.media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.media-preview {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: center;
}

.media-thumbnail {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #373b40;
}

.media-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
}

/* Navigation and Controls */
.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  margin-top: auto;
  gap: 1rem;
}

.nav-button {
  padding: 0.75rem 1.5rem;
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  min-width: 100px;
}

.complete-button {
  width: 100%;
  padding: 1rem;
  background-color: #52c41a;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1.1rem;
}

.complete-button:hover:not(:disabled) {
  background-color: #389e0d;
}

.complete-button:disabled {
  background-color: #373b40;
  cursor: not-allowed;
}

/* Modal Styles */
.media-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.media-modal-content {
  background: #2a2e33;
  padding: 1.5rem;
  border-radius: 8px;
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  border: 1px solid #373b40;
}

.media-preview-content {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.media-modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1;
}

/* Loading and Error States */
.modal-loading,
.modal-error,
.modal-empty {
  text-align: center;
  padding: 2rem;
  color: #8a8d91;
}

.modal-error {
  color: #ff4d4f;
}

/* Scrollbar Styles */
.modal-body::-webkit-scrollbar,
.flashcard::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-track,
.flashcard::-webkit-scrollbar-track {
  background: #1a1d21;
}

.modal-body::-webkit-scrollbar-thumb,
.flashcard::-webkit-scrollbar-thumb {
  background: #373b40;
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb:hover,
.flashcard::-webkit-scrollbar-thumb:hover {
  background: #454950;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    margin: 1rem;
  }

  .flashcard {
    padding: 1.5rem;
    min-height: auto;
  }

  .media-preview {
    flex-direction: column;
    align-items: center;
  }
  
  .media-thumbnail {
    width: 100%;
    max-width: 200px;
    height: auto;
    aspect-ratio: 1;
  }
  
  .media-button {
    width: 100%;
    justify-content: center;
  }

  .nav-button {
    padding: 0.5rem 1rem;
    min-width: 80px;
  }
  
  .flashcard h3 {
    font-size: 1.5rem;
  }
}

/* Additional Media Queries */
@media (max-width: 768px) {
  .flashcard {
    min-height: 300px;
    max-height: 500px;
    padding: 1.5rem;
  }
  
  .content-section {
    padding: 0.25rem;
  }

  .back-link {
    padding: 0.5rem;
  }

  .word-title {
    font-size: 1.5rem;
  }

  .media-preview {
    flex-direction: column;
  }
  
  .media-thumbnail {
    width: 100%;
    max-width: 200px;
    height: auto;
    aspect-ratio: 1;
  }
  
  .media-button {
    width: 100%;
    justify-content: center;
  }
}

/* Accessibility and Reduced Motion */
@media (prefers-reduced-motion: reduce) {
  .flashcard,
  .media-button,
  .nav-button,
  .complete-button {
    transition: none;
  }
  
  .flashcard:hover {
    transform: none;
  }
}

/* High Contrast Mode */
@media (prefers-contrast: high) {
  .modal-content,
  .flashcard {
    border: 2px solid white;
  }
  
  .media-button,
  .nav-button,
  .complete-button {
    border: 2px solid white;
  }
}

/* Progress History Modal Styles */
.stats-container {
  padding: 1.5rem;
  background-color: #2a2e33;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.stat-card {
  background-color: #1a1d21;
  border: 1px solid #373b40;
  border-radius: 6px;
  padding: 1rem;
  text-align: center;
}

.stat-label {
  color: #8a8d91;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.text-blue {
  color: #0084ff;
}

.text-green {
  color: #52c41a;
}

.text-purple {
  color: #7c3aed;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1.5rem 1.5rem;
}

.history-item {
  background-color: #2a2e33;
  border: 1px solid #373b40;
  border-radius: 8px;
  padding: 1rem;
}

.history-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.history-date {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #8a8d91;
}

.history-block-number {
  color: #52c41a;
  font-weight: 500;
}

.history-words {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: #1a1d21;
  border-radius: 6px;
}

.history-word {
  color: #0084ff;
  background-color: rgba(0, 132, 255, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
}

.history-times {
  color: #8a8d91;
  font-size: 0.875rem;
  padding-top: 0.75rem;
  border-top: 1px solid #373b40;
}

.history-empty {
  text-align: center;
  padding: 2rem;
  color: #8a8d91;
}

.modal-close {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 0.5rem;
}

.modal-close:hover {
  color: #8a8d91;
}

/* Progress History Styles */
.progress-history-container {
  background: #1a1d21;
  border: 1px solid #373b40;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1rem;
}

.progress-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #373b40;
}

.progress-history-title {
  font-size: 1.25rem;
  color: white;
  font-weight: bold;
}

.progress-history-close {
  background: none;
  border: none;
  color: #8a8d91;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
}

.progress-history-item {
  background: #2a2e33;
  border: 1px solid #373b40;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.progress-history-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.progress-history-words {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.progress-history-word {
  color: #0084ff;
  font-weight: 500;
}

.progress-history-times {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  color: #8a8d91;
  padding-top: 0.75rem;
  border-top: 1px solid #373b40;
}

.progress-history-date {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.progress-history-date svg {
  width: 16px;
  height: 16px;
  color: #8a8d91;
}

.progress-history-empty {
  text-align: center;
  padding: 2rem;
  color: #8a8d91;
}

/* Print Styles */
@media print {
  .modal-overlay {
    position: relative;
    height: auto;
    background: none;
  }

  .media-button,
  .nav-button,
  .complete-button {
    display: none;
  }

  .flashcard {
    break-inside: avoid;
    box-shadow: none;
    border: 1px solid #000;
  }

  .media-thumbnail {
    max-width: 300px;
    height: auto;
  }
}

.progress-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: all 0.2s;
  background-color: #2563eb;  /* Bright blue background */
  color: white;  /* White text */
  font-weight: 500;  /* Medium font weight for better readability */
}

.progress-button:hover {
  background-color: #1d4ed8;  /* Darker blue on hover */
}

.progress-button:focus {
  outline: 2px solid #60a5fa;  /* Light blue focus ring */
  outline-offset: 2px;
}