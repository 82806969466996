/* ConversationStarter.module.css */
.startButton {
  width: 100%;
  padding: 1rem;
  background-color: #dc2626 !important; /* red-600 */
  color: #ffffff !important;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.2s ease;
  border: none;
  cursor: pointer;
}

.startButton:hover:not(:disabled) {
  background-color: #b91c1c !important; /* red-700 */
}

.startButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.buttonText {
  font-size: 1.125rem;
  font-weight: 500;
  color: #ffffff !important;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #ffffff !important;
}