/* Chat.css - Complete styling for chat interface with dynamic height handling */

/* Main Container Styles
   The chat interface uses a flex layout to enable dynamic content flow and proper spacing */
   .chat-interface {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
    background: #000000;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.2);
    color: white;
    gap: 20px;
    box-sizing: border-box;
  }
  
  /* Header Section
     Sticky positioning keeps it visible while scrolling through content */
  .chat-header {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 15px 20px;
    border-bottom: 1px solid #2a2e33;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1a1d21;
    flex-shrink: 0;
  }
  
  .chat-header h2 {
    margin: 0;
    font-size: 1.2rem;
    color: white;
  }
  
  /* Context Selection and Navigation Controls */
  .context-selector {
    padding: 8px 12px;
    border: 1px solid #2a2e33;
    border-radius: 8px;
    background-color: #2a2e33;
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
  }
  
  .new-chat-button {
    padding: 8px 16px;
    background-color: #0084ff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
  }
  
  .new-chat-button:hover {
    background-color: #0073e6;
  }
  
  /* Main Content Area
     Enables scrolling and proper content flow */
  .chat-content {
    flex: 1;
    overflow-y: auto;
    padding: 10px 0;
    scrollbar-width: thin;
    scrollbar-color: #373b40 #1a1d21;
  }
  
  /* Message Styling */
  .message {
    margin: 10px 0;
    padding: 15px;
    border-radius: 8px;
    max-width: 85%;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  .message.system {
    align-self: center;
    background-color: #2a2e33;
    color: #8a8d91;
    font-style: italic;
    font-size: 0.9em;
    max-width: 90%;
  }
  
  .message.system.isTranscription {
    background-color: #2a2e33;
    border: 1px solid #373b40;
  }
  
  /* Input Section
     Fixed at the bottom with proper spacing and interaction states */
  .input-section {
    position: sticky;
    bottom: 0;
    padding: 20px;
    border-top: 1px solid #2a2e33;
    display: flex;
    gap: 10px;
    background-color: #1a1d21;
    flex-shrink: 0;
    z-index: 10;
  }
  
  .text-input-form {
    display: flex;
    gap: 10px;
    flex: 1;
  }
  
  .text-input {
    flex: 1;
    padding: 12px;
    border: 1px solid #2a2e33;
    border-radius: 8px;
    font-size: 16px;
    background-color: #2a2e33;
    color: white;
  }
  
  .text-input:focus {
    outline: none;
    border-color: #0084ff;
  }
  
  .send-button {
    padding: 12px 24px;
    background-color: #0084ff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .send-button:hover:not(:disabled) {
    background-color: #0073e6;
  }
  
  .send-button:disabled {
    background-color: #2a2e33;
    cursor: not-allowed;
  }
  
  /* Transcription Area
     Provides feedback for voice input */
  .transcription-area {
    padding: 10px 20px;
    border-top: 1px solid #2a2e33;
    background-color: #1a1d21;
    flex-shrink: 0;
  }
  
  .transcription-text {
    color: #8a8d91;
    font-style: italic;
    font-size: 0.9em;
    margin: 5px 0;
  }
  
  .speaking-indicator {
    color: #0084ff;
    font-style: italic;
    font-size: 0.9em;
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  /* Speaking Animation */
  .speaking-indicator::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #0084ff;
    border-radius: 50%;
    display: inline-block;
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  /* Custom Scrollbar Styling */
  .chat-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .chat-content::-webkit-scrollbar-track {
    background: #1a1d21;
  }
  
  .chat-content::-webkit-scrollbar-thumb {
    background: #373b40;
    border-radius: 4px;
  }
  
  .chat-content::-webkit-scrollbar-thumb:hover {
    background: #454950;
  }
  
  /* Responsive Design
     Adjusts layout and spacing for different screen sizes */
  @media (max-width: 768px) {
    .chat-interface {
      padding: 10px;
      gap: 10px;
    }
  
    .message {
      max-width: 95%;
    }
  
    .input-section {
      padding: 15px;
    }
  
    .send-button {
      padding: 12px 16px;
    }
  
    .chat-header {
      padding: 10px 15px;
    }
  }
  
  /* Accessibility Improvements */
  @media (prefers-reduced-motion: reduce) {
    .new-chat-button,
    .send-button {
      transition: none;
    }
  
    .speaking-indicator::after {
      animation: none;
    }
  }
  
  /* High Contrast Mode Support */
  @media (prefers-contrast: high) {
    .chat-interface {
      border: 2px solid white;
    }
  
    .text-input,
    .context-selector {
      border: 2px solid white;
    }
  }

  /* Message Limit Styles */
.message-limit-alert {
  padding: 12px 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  font-size: 14px;
}

.message-limit-alert.warning {
  background-color: rgba(255, 171, 0, 0.15);
  color: #ffab00;
  border: 1px solid rgba(255, 171, 0, 0.3);
}

.message-limit-alert.error {
  background-color: rgba(255, 77, 79, 0.15);
  color: #ff4d4f;
  border: 1px solid rgba(255, 77, 79, 0.3);
}

.message-limit-badge {
  position: absolute;
  top: -30px;
  right: 0;
  background-color: #ffab00;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

/* Update input section for badge positioning */
.input-section {
  position: relative;
  margin-top: 32px;  /* Add space for the badge */
}