.voice-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  
  .voice-input-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .voice-input-button.recording {
    background-color: #dc3545;
    animation: pulse 1.5s infinite;
  }
  
  .voice-input-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .voice-input-error {
    color: #dc3545;
    font-size: 0.8rem;
    margin-top: 4px;
  }
  
  .recording-indicator {
    color: #dc3545;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .recording-indicator::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #dc3545;
    border-radius: 50%;
    display: inline-block;
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }