@tailwind base;
@tailwind components;
@tailwind utilities;

/* Override Tailwind's default background */
body {
  background-color: #0f172a !important;
}

#root {
  background-color: #0f172a !important;
}


  /* Override background for trainer page container */
  .css-1xy5ojf  {
    background: none !important;
    background-image: none !important;
  }
  
  /* Reset any inherited background styles */
  .css-1xy5ojf :before,
  .css-1xy5ojf :after {
    background: none !important;
    background-image: none !important;
  }
  
  /* Ensure the trainer page content uses a neutral dark background */
  .css-1xy5ojf  {
    background-color: #0f172a !important;
  }
  
  /* Remove any background animations or transitions */
  .css-lgfs7wv {
    transition: none !important;
    animation: none !important;
  }
  
  /* Ensure text remains visible against dark background */
  .css-1xy5ojf  {
    color: #ffffff !important;
  }

  /* Override background for trainer page container */
  .css-h9p7e0 {
    background: none !important;
    background-image: none !important;
  }
  
  /* Reset any inherited background styles */
  .css-h9p7e0  :before,
  .css-h9p7e0  :after {
    background: none !important;
    background-image: none !important;
  }
  
  /* Ensure the trainer page content uses a neutral dark background */
  .css-1xy5ojf  {
    background-color: #0f172a !important;
  }
  
  /* Remove any background animations or transitions */
  .css-lgfs7wv {
    transition: none !important;
    animation: none !important;
  }
  
  /* Ensure text remains visible against dark background */
  .css-1xy5ojf  {
    color: #ffffff !important;
  }


  
