/* Chat container */
.message-list {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Message bubbles */
  .message {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
  }
  
  .message.user .message-content {
    margin-left: auto;
    background-color: #22c55e; /* green-600 */
    border-radius: 1rem;
    padding: 1rem;
    max-width: 85%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .message.bot .message-content {
    margin-right: auto;
    background-color: #267bdc; /* red-600 */
    border-radius: 1rem;
    padding: 1rem;
    max-width: 85%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  /* Message text */
  .message-content p {
    color: white;
    font-weight: 500;
    margin: 0;
    line-height: 1.5;
  }
  
  /* Timestamp */
  .message-content .timestamp {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 0.5rem;
    display: block;
  }
  
  /* Feedback button */
  .feedback-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #eab308; /* yellow-500 */
    color: black;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    margin-top: 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: background-color 0.2s;
  }
  
  .feedback-button:hover {
    background-color: #ca8a04; /* yellow-600 */
  }
  
  /* Divider for follow-up questions */
  .follow-up-divider {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0.75rem 0;
  }
  
  /* Expanded feedback sections */
  .feedback-section {
    background-color: #1f2937; /* gray-800 */
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.5rem;
  }
  
  .feedback-section h4 {
    color: #e5e7eb; /* gray-200 */
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  /* Mobile optimizations */
  @media (max-width: 640px) {
    .message-content {
      max-width: 90%;
    }
    
    .feedback-section {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  
  /* System messages */
  .message.system .message-content {
    background-color: #4b5563; /* gray-600 */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  /* Error messages */
  .message.error .message-content {
    background-color: #991b1b; /* red-800 */
  }