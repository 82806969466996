/* VocabAdmin.css */
.vocab-admin-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1a1d21;
  color: rgb(16, 3, 63);
}

.vocab-admin-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: rgb(3, 248, 68);
}

.vocab-word-card {
  background: #2a2e33;
  border: 1px solid #373b40;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.vocab-word-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: rgb(0, 0, 0);
}

.vocab-word-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.vocab-input-group {
  display: flex;
  flex-direction: column;
}

.vocab-input-group.full-width {
  grid-column: span 2;
}

.vocab-input-group label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #8a8d91;
}

.vocab-input-group input {
  padding: 8px 12px;
  border: 1px solid #373b40;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  background-color: #2a2e33;
  color: rgb(248, 219, 219);
}

.vocab-input-group input:focus {
  outline: none;
  border-color: #0084ff;
  box-shadow: 0 0 0 2px rgba(0, 132, 255, 0.1);
}

.vocab-input-group input::placeholder {
  color: #000102;
}

.vocab-admin-error {
  color: #ff4d4f;
  padding: 10px;
  margin: 10px 0;
  background-color: rgba(255, 77, 79, 0.1);
  border: 1px solid #ff4d4f;
  border-radius: 4px;
}

.vocab-admin-success {
  color: #52c41a;
  padding: 10px;
  margin: 10px 0;
  background-color: rgba(82, 196, 26, 0.1);
  border: 1px solid #52c41a;
  border-radius: 4px;
}

.vocab-admin-submit {
  width: 100%;
  padding: 12px;
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  transition: background-color 0.2s ease;
}

.vocab-admin-submit:disabled {
  background-color: #373b40;
  cursor: not-allowed;
}

.vocab-admin-submit:hover:not(:disabled) {
  background-color: #0073e6;
}

/* Form validation styles */
.vocab-input-group input.error {
  border-color: #ff4d4f;
}

.vocab-input-error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
}

/* Loading state */
.vocab-admin-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #8a8d91;
}

.vocab-admin-loading::after {
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid #8a8d91;
  border-top-color: transparent;
  border-radius: 50%;
  margin-left: 10px;
  animation: spin 1s linear infinite;
}

/* Media uploads */
.vocab-media-upload {
  border: 2px dashed #373b40;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.vocab-media-upload:hover {
  border-color: #0084ff;
  background-color: rgba(0, 132, 255, 0.05);
}

.vocab-media-preview {
  margin-top: 10px;
  padding: 10px;
  background-color: #2a2e33;
  border-radius: 4px;
}

.vocab-media-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.vocab-media-remove {
  color: #ff4d4f;
  background: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}

/* Animation keyframes */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive styles */
@media (max-width: 640px) {
  .vocab-word-grid {
    grid-template-columns: 1fr;
  }

  .vocab-input-group.full-width {
    grid-column: auto;
  }

  .vocab-admin-container {
    padding: 15px;
  }

  .vocab-word-card {
    padding: 15px;
  }
}

/* High contrast mode */
@media (prefers-contrast: high) {
  .vocab-word-card {
    border: 2px solid white;
  }

  .vocab-input-group input {
    border: 2px solid white;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .vocab-admin-submit,
  .vocab-media-upload {
    transition: none;
  }

  .vocab-admin-loading::after {
    animation: none;
  }
}

.reorder-item {
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: grab;
}

.reorder-item:active {
  cursor: grabbing;
}

.edit-modal-content {
  max-height: 70vh;
  overflow-y: auto;
  padding: 1rem;
}