/* Container styles */
.writing-assessment-container {
  max-width: 800px;
  width: 95%;
  margin: 2rem auto;
  padding: 20px;
  background-color: #1a1d21;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  min-height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Writing prompt styles */
.writing-prompt {
  margin-bottom: 24px;
}

.writing-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 16px;
  line-height: 1.3;
}

.writing-instruction {
  font-size: 16px;
  color: #8a8d91;
  margin-bottom: 16px;
  line-height: 1.5;
}

/* Vocabulary words styles */
.vocab-words {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
  padding: 8px;
  background-color: rgba(42, 46, 51, 0.5);
  border-radius: 8px;
}

.vocab-word {
  background-color: rgba(0, 132, 255, 0.15);
  color: #0084ff;
  padding: 6px 12px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid rgba(0, 132, 255, 0.2);
  transition: background-color 0.2s;
}

.vocab-word:hover {
  background-color: rgba(0, 132, 255, 0.25);
}

/* Text input area styles */
.writing-input-container {
  flex: 1;
  min-height: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.paragraph-input {
  width: 100%;
  flex: 1;
  min-height: 200px;
  max-height: calc(100vh - 400px);
  padding: 16px;
  border: 2px solid #373b40;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.6;
  resize: vertical;
  font-family: inherit;
  background-color: #2a2e33;
  color: white;
  transition: border-color 0.2s, box-shadow 0.2s;
  overflow-y: auto;
}

.paragraph-input:focus {
  outline: none;
  border-color: #0084ff;
  box-shadow: 0 0 0 2px rgba(0, 132, 255, 0.1);
}

.paragraph-input::placeholder {
  color: #8a8d91;
}

.paragraph-input:disabled {
  background-color: #1a1d21;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Error message styles */
.error-message {
  background-color: rgba(255, 77, 79, 0.1);
  color: #ff4d4f;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid rgba(255, 77, 79, 0.2);
}

/* Button styles */
.button-container {
  margin-top: auto;
  padding-top: 20px;
}

.submit-button {
  width: 100%;
  padding: 12px 24px;
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover:not(:disabled) {
  background-color: #0073e6;
}

.submit-button:disabled {
  background-color: #373b40;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Assessment results styles */
.assessment-results {
  background-color: #2a2e33;
  padding: 24px;
  border-radius: 8px;
  margin-top: 24px;
  border: 1px solid #373b40;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.assessment-score {
  font-size: 28px;
  font-weight: bold;
  color: #52c41a;
  margin-bottom: 24px;
  text-align: center;
  padding: 16px;
  background-color: rgba(82, 196, 26, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(82, 196, 26, 0.2);
}

/* Feedback section styles */
.feedback-section {
  margin-bottom: 24px;
  background-color: #1a1d21;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #373b40;
}

.feedback-section h3 {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 2px solid #373b40;
}

.feedback-item {
  padding: 12px 0;
  border-bottom: 1px solid #373b40;
}

.feedback-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

/* Loading indicator styles */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #8a8d91;
  padding: 20px;
}

.loading-indicator::after {
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid #8a8d91;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Scrollbar styles */
.paragraph-input::-webkit-scrollbar,
.assessment-results::-webkit-scrollbar {
  width: 8px;
}

.paragraph-input::-webkit-scrollbar-track,
.assessment-results::-webkit-scrollbar-track {
  background: #1a1d21;
}

.paragraph-input::-webkit-scrollbar-thumb,
.assessment-results::-webkit-scrollbar-thumb {
  background: #373b40;
  border-radius: 4px;
}

.paragraph-input::-webkit-scrollbar-thumb:hover,
.assessment-results::-webkit-scrollbar-thumb:hover {
  background: #454950;
}

/* Responsive styles */
@media (max-width: 768px) {
  .writing-assessment-container {
    width: 100%;
    margin: 0;
    min-height: 100vh;
    border-radius: 0;
    padding: 16px;
  }

  .writing-title {
    font-size: 20px;
  }

  .vocab-word {
    font-size: 13px;
    padding: 4px 10px;
  }

  .paragraph-input {
    font-size: 14px;
    min-height: 160px;
    max-height: calc(100vh - 350px);
  }

  .assessment-score {
    font-size: 24px;
    padding: 12px;
  }

  .feedback-section {
    padding: 12px;
  }

  .feedback-section h3 {
    font-size: 16px;
  }
}

/* Print styles */
@media print {
  .writing-assessment-container {
    box-shadow: none;
    padding: 0;
    margin: 0;
    min-height: auto;
  }

  .submit-button,
  .continue-button {
    display: none;
  }

  .paragraph-input {
    border: none;
    min-height: auto;
  }

  .assessment-results {
    border: none;
    padding: 0;
    max-height: none;
  }
}

/* High contrast mode */
@media (prefers-contrast: high) {
  .vocab-word {
    border: 2px solid #0084ff;
  }

  .paragraph-input {
    border: 2px solid white;
  }

  .error-message {
    border: 2px solid #ff4d4f;
  }

  .assessment-results,
  .feedback-section {
    border: 2px solid white;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .paragraph-input,
  .submit-button,
  .vocab-word {
    transition: none;
  }

  .loading-indicator::after {
    animation: none;
  }
}